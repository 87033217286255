// 按需引入vant 组件库
import Vue from 'vue'

import { Tabbar, TabbarItem ,NavBar ,Toast,Dialog} from 'vant';


//Tabbar
Vue.use(Tabbar);        
Vue.use(TabbarItem);  
//NavBar 导航栏
Vue.use(NavBar);    
// 提示文字
Vue.use(Toast);    

// 确定弹窗
Vue.use( Dialog); 


