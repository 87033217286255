import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// 引入页面 路由懒加载
// 一级路由
const Login = ()=> import('@/views/login')                 //登录页
const Myorder = ()=> import('@/views/myorder')             //订单管理页
const Pay = ()=> import('@/views/pay')                     //结算支付页
const Prodetail = ()=> import('@/views/prodetail')         //商品详情页
const Search = ()=> import('@/views/search/index.vue')     //搜索页
const SearchList = ()=> import('@/views/search/list.vue')  //搜索列表页
const Layout = ()=> import('@/views/layout')               //首页架子
// 二级路由
const Cart = ()=> import('@/views/layout/cart.vue')        //购物车
const Category = ()=> import('@/views/layout/category.vue')//分类页
const User = ()=> import('@/views/layout/user.vue')        //我的
const Home = ()=> import('@/views/layout/home.vue')        //主页

const router = new VueRouter({
  routes: [
     // 一级页面  meta配置页面title
     {path:'/login',component:Login,               meta: { needLogin: true, title: '登录页' }},
     {path:'/myorder',component:Myorder,           meta: { needLogin: true, title: '订单管理页' }},
     {path:'/pay',component:Pay,                   meta: { needLogin: true, title: '结算支付页页' }},
     {path:'/prodetail',component:Prodetail,       meta: { needLogin: true, title: '商品详情页' }},
     {path:'/search',component:Search,             meta: { needLogin: true, title: '搜索页' }},
     {path:'/searchList',component:SearchList,     meta: { needLogin: true, title: '搜索列表页' }},
     {path:'/',component:Layout, redirect:'home',  meta: { needLogin: true, title: '主页' }, 
      // 二级页面
      children:[
        {path:'/cart',component:Cart,              meta: { needLogin: true, title: '确定订单' }},
        {path:'/category',component:Category,      meta: { needLogin: true, title: '交易成功' }},
        {path:'/user',component:User,              meta: { needLogin: true, title: '我的' }},
        {path:'/home',component:Home,              meta: { needLogin: true, title: '商品详情页' }},
     ]},
  ]
})
// 全局前置守卫
router.beforeEach((to, from, next) => {
   // 配置项目title
   document.title = to.meta.title || '移动端vue2'
   next()
 })
export default router
